.root {
  .whiteButtonIcon {
    margin-right: 5px; }
  .whiteButton {
    line-height: 21px;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-left: 20px; } }

.sidenavRoot {
  &.manageButton {
    line-height: 21px;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-left: 20px; }
  .manageIcon {
    font-size: 1.3rem;
    margin-right: 5px; }
  .listIconSmall {
    min-width: 30px; }
  .menuSectionTitle {
    margin: 10px 0 0 10px; } }


