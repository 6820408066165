@import '../../sass/variables';
@import '../../sass/transitions';
@import '../../sass/commons';

.root {
  flex-grow: 1;

  .menuItemContainer {
    display: flex;
    justify-content: space-between;
    fieldset {
      border-color: white!important;
      margin: 5px; } }

  .displayFlexCenter {
    display: flex;
    align-items: center; }

  .logo {
    height: 38px;
    margin-right: 20px; }

  .headerSelect {
    color: white;
    border-bottom: white;
    label {
      transform: none;
      color: #c5c5c5;
      top: 8px; }
    > div {
      width: 250px;
      margin-top: 0px;
      &:before {
        border-color: white!important; }
      &:after {
        border-color: white!important; } } }

  .whiteIcon {
    color: white; }
  .rightContainer {
    display: flex;
    align-items: center; }

  .search {
    width: auto;
    position: relative;
    margin-left: 20px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.15); }

  .searchIcon {
    width: 56px;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center; }

  .resetSearchIcon {
    color: rgba(255, 255, 255, 0.5);
    &:hover {
      color: rgba(255, 255, 255, 1);
      background-color: inherit; } }

  .inputRoot {
    color: inherit; }

  .inputInput {
    width: 200px;
    padding: 8px 8px 8px 56px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:focus {
      width: 300px; } } }

.paddingLeft20 {
  padding-left: 20px; }
