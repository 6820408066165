/* Variable fonts usage:
:root { font-family: "Inter", sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: "InterVariable", sans-serif; font-optical-sizing: auto; }
} */
@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable.woff2") format("woff2");
}
@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable-Italic.woff2") format("woff2");
}

/* static fonts */
@font-face { font-family: "Inter"; font-style: normal; font-weight: 100; font-display: swap; src: url("Inter-Thin.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 100; font-display: swap; src: url("Inter-ThinItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 200; font-display: swap; src: url("Inter-ExtraLight.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 200; font-display: swap; src: url("Inter-ExtraLightItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 300; font-display: swap; src: url("Inter-Light.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 300; font-display: swap; src: url("Inter-LightItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 400; font-display: swap; src: url("Inter-Regular.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 400; font-display: swap; src: url("Inter-Italic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 500; font-display: swap; src: url("Inter-Medium.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 500; font-display: swap; src: url("Inter-MediumItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 600; font-display: swap; src: url("Inter-SemiBold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 600; font-display: swap; src: url("Inter-SemiBoldItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 700; font-display: swap; src: url("Inter-Bold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 700; font-display: swap; src: url("Inter-BoldItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 800; font-display: swap; src: url("Inter-ExtraBold.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 800; font-display: swap; src: url("Inter-ExtraBoldItalic.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: normal; font-weight: 900; font-display: swap; src: url("Inter-Black.woff2") format("woff2"); }
@font-face { font-family: "Inter"; font-style: italic; font-weight: 900; font-display: swap; src: url("Inter-BlackItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 100; font-display: swap; src: url("InterDisplay-Thin.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 100; font-display: swap; src: url("InterDisplay-ThinItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 200; font-display: swap; src: url("InterDisplay-ExtraLight.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 200; font-display: swap; src: url("InterDisplay-ExtraLightItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 300; font-display: swap; src: url("InterDisplay-Light.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 300; font-display: swap; src: url("InterDisplay-LightItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 400; font-display: swap; src: url("InterDisplay-Regular.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 400; font-display: swap; src: url("InterDisplay-Italic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 500; font-display: swap; src: url("InterDisplay-Medium.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 500; font-display: swap; src: url("InterDisplay-MediumItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 600; font-display: swap; src: url("InterDisplay-SemiBold.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 600; font-display: swap; src: url("InterDisplay-SemiBoldItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 700; font-display: swap; src: url("InterDisplay-Bold.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 700; font-display: swap; src: url("InterDisplay-BoldItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 800; font-display: swap; src: url("InterDisplay-ExtraBold.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 800; font-display: swap; src: url("InterDisplay-ExtraBoldItalic.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 900; font-display: swap; src: url("InterDisplay-Black.woff2") format("woff2"); }
@font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 900; font-display: swap; src: url("InterDisplay-BlackItalic.woff2") format("woff2"); }

@font-feature-values InterVariable {
    @character-variant {
        cv01: 1; cv02: 2; cv03: 3; cv04: 4; cv05: 5; cv06: 6; cv07: 7; cv08: 8;
        cv09: 9; cv10: 10; cv11: 11; cv12: 12; cv13: 13;
        alt-1:            1; /* Alternate one */
        alt-3:            9; /* Flat-top three */
        open-4:           2; /* Open four */
        open-6:           3; /* Open six */
        open-9:           4; /* Open nine */
        lc-l-with-tail:   5; /* Lower-case L with tail */
        simplified-u:     6; /* Simplified u */
        alt-double-s:     7; /* Alternate German double s */
        uc-i-with-serif:  8; /* Upper-case i with serif */
        uc-g-with-spur:  10; /* Capital G with spur */
        single-story-a:  11; /* Single-story a */
        compact-lc-f:    12; /* Compact f */
        compact-lc-t:    13; /* Compact t */
    }
    @styleset {
        ss01: 1; ss02: 2; ss03: 3; ss04: 4; ss05: 5; ss06: 6; ss07: 7; ss08: 8;
        open-digits: 1;                /* Open digits */
        disambiguation: 2;             /* Disambiguation (with zero) */
        disambiguation-except-zero: 4; /* Disambiguation (no zero) */
        round-quotes-and-commas: 3;    /* Round quotes &amp; commas */
        square-punctuation: 7;         /* Square punctuation */
        square-quotes: 8;              /* Square quotes */
        circled-characters: 5;         /* Circled characters */
        squared-characters: 6;         /* Squared characters */
    }
}
@font-feature-values Inter {
    @character-variant {
        cv01: 1; cv02: 2; cv03: 3; cv04: 4; cv05: 5; cv06: 6; cv07: 7; cv08: 8;
        cv09: 9; cv10: 10; cv11: 11; cv12: 12; cv13: 13;
        alt-1:            1; /* Alternate one */
        alt-3:            9; /* Flat-top three */
        open-4:           2; /* Open four */
        open-6:           3; /* Open six */
        open-9:           4; /* Open nine */
        lc-l-with-tail:   5; /* Lower-case L with tail */
        simplified-u:     6; /* Simplified u */
        alt-double-s:     7; /* Alternate German double s */
        uc-i-with-serif:  8; /* Upper-case i with serif */
        uc-g-with-spur:  10; /* Capital G with spur */
        single-story-a:  11; /* Single-story a */
        compact-lc-f:    12; /* Compact f */
        compact-lc-t:    13; /* Compact t */
    }
    @styleset {
        ss01: 1; ss02: 2; ss03: 3; ss04: 4; ss05: 5; ss06: 6; ss07: 7; ss08: 8;
        open-digits: 1;                /* Open digits */
        disambiguation: 2;             /* Disambiguation (with zero) */
        disambiguation-except-zero: 4; /* Disambiguation (no zero) */
        round-quotes-and-commas: 3;    /* Round quotes &amp; commas */
        square-punctuation: 7;         /* Square punctuation */
        square-quotes: 8;              /* Square quotes */
        circled-characters: 5;         /* Circled characters */
        squared-characters: 6;         /* Squared characters */
    }
}
@font-feature-values InterDisplay {
    @character-variant {
        cv01: 1; cv02: 2; cv03: 3; cv04: 4; cv05: 5; cv06: 6; cv07: 7; cv08: 8;
        cv09: 9; cv10: 10; cv11: 11; cv12: 12; cv13: 13;
        alt-1:            1; /* Alternate one */
        alt-3:            9; /* Flat-top three */
        open-4:           2; /* Open four */
        open-6:           3; /* Open six */
        open-9:           4; /* Open nine */
        lc-l-with-tail:   5; /* Lower-case L with tail */
        simplified-u:     6; /* Simplified u */
        alt-double-s:     7; /* Alternate German double s */
        uc-i-with-serif:  8; /* Upper-case i with serif */
        uc-g-with-spur:  10; /* Capital G with spur */
        single-story-a:  11; /* Single-story a */
        compact-lc-f:    12; /* Compact f */
        compact-lc-t:    13; /* Compact t */
    }
    @styleset {
        ss01: 1; ss02: 2; ss03: 3; ss04: 4; ss05: 5; ss06: 6; ss07: 7; ss08: 8;
        open-digits: 1;                /* Open digits */
        disambiguation: 2;             /* Disambiguation (with zero) */
        disambiguation-except-zero: 4; /* Disambiguation (no zero) */
        round-quotes-and-commas: 3;    /* Round quotes &amp; commas */
        square-punctuation: 7;         /* Square punctuation */
        square-quotes: 8;              /* Square quotes */
        circled-characters: 5;         /* Circled characters */
        squared-characters: 6;         /* Squared characters */
    }
}
