
.root {
  .positionRelative {
    position: relative; }

  .centeredItem {
    color: green;
    position: absolute;
    top: 50%;
    left: 50%; }

  .btnProgress {
    margin-top: -12px;
    margin-left: -12px; }

  .checkIcon {
    font-size: 38px;
    margin-top: -20px;
    margin-left: -20px; } }
